<template>
  <commonTitle v-bind:datas="data"></commonTitle>
  <div class="news">
    <div class="w1200">
      <div class="box" ref="box">
        <div class="main">
          <div class="container" ref="container">
              <!-- <div class="swiper-container" style="margin-bottom:20px;">
              <Carousel
                :autoplay="true"
                :duration="2000"
                :initIndex="0"
                :direction="true"
                directionMode="hover"
                :directionSize="20"
                directionColor="skyblue"
                :indicator="true"
                indicatorMode="always"
                indicatorColor="white"
                indicatorActiveColor="skyblue"
                
                >
                <CarouselItem v-for="(item, index) in datas" :key="index" :idx="index">
                    <img :src="item" />
                </CarouselItem>
            </Carousel>
            </div> -->

            <div>
              <div class="tab">
                <div
                  v-for="(item,index) in tabList" :key="index"
                  @click='selectTab(item.id)'
                  class="tabItem"
                >
                  {{item.name}}
                </div>
              </div>
			  
			  <!-- 公司新闻 -->
              <div class="newsList" ref="newsList" v-show="activeIndex == 0">
                <!-- <a href="/detail.html?1421044908469596162" target="_blank"> -->
                  <!-- <div class="newItem" onclick="window.open('https://mp.weixin.qq.com/s/UQ2_2YrfeA4z0JiudKI6Og')"> -->
                    <div class="newItem" @click="jumpto('/NewsPage/news-DLNonline')">
					<img
                      class="newsImg"
                      src="@/assets/images/news/new_DLN.png"
                      alt="项目北美正式上线？"
                    />
                    <div class="text">
                      <h4 class="title">DLN项目北美正式上线</h4>
                      <p class="tip"></p>
                      <div class="footer">
                        2022-08-30 17:47:52
                        <!-- <span class="fr">1005人已阅</span> -->
                      </div>
                    </div>
                  </div> 
                <!-- </a> -->
                <!-- <a href="/detail.html?1421043910306050050" target="_blank"> -->
				<!-- window.open('https://mp.weixin.qq.com/s/UQ2_2YrfeA4z0JiudKI6Og') -->
                  <div class="newItem" @click="jumpto('/NewsPage/newsDemo')">
                    <img
                      class="newsImg"
                      src="@/assets/images/news/new_HEZUO.png"
                      alt="忆能和霍尼韦尔达成储能合作协议"
                    />
                    <div class="text">
                      <h4 class="title">公司和霍尼韦尔达成储能合作协议</h4>
                      <p class="tip"></p>
                      <div class="footer">
                        <!-- <img src="http://jiuyixueyuan.oss-cn-shanghai.aliyuncs.com/jiuyi/system/40ec26ab2a0c4838bef69db87238f3fc.jpg" alt="会务系统有什么好处" />
                    领课网络科技 &nbsp;&nbsp;|&nbsp;&nbsp;-->
                        2021-07-30 17:43:54
                        <!-- <span class="fr">208人已阅</span> -->
                      </div>
                    </div>
                  </div> 
                <!-- </a> -->
              </div>
			  <!-- 行业动态 -->
			  <div class="newsList" v-show="activeIndex == 2">
			    <!-- <a href="/detail.html?1421044908469596162" target="_blank"> -->
			      <div class="newItem" onclick="window.open('http://cesa.escn.com.cn/news/show-1468414.html')">
			        <img
			          class="newsImg"
			          src="@/assets/images/news/hangye_1.png"
			          alt="项目北美正式上线？"
			        />
			        <div class="text">
			          <h4 class="title">Qcells公司计划在纽约部署三个电池储能项目</h4>
			          <p class="tip"></p>
			          <div class="footer">
			            <!-- <img src="http://jiuyixueyuan.oss-cn-shanghai.aliyuncs.com/jiuyi/system/b24afb1554c34edc8f77ff9beac1b826.jpg" alt="网校系统有什么作用？" />
			        领课网络科技 &nbsp;&nbsp;|&nbsp;&nbsp;-->
			            2022-09-20 11:47:52
			            <!-- <span class="fr">1005人已阅</span> -->
			          </div>
			        </div>
			      </div> 
			    <!-- </a> -->
			    <!-- <a href="/detail.html?1421043910306050050" target="_blank"> -->
			      <div class="newItem" onclick="window.open('http://escn.com.cn/news/show-1467699.html')">
			        <img
			          class="newsImg"
			          src="@/assets/images/news/hangye-2.png"
			          alt="纽约州提供1660万美元扶持储能"
			        />
			        <div class="text">
			          <h4 class="title">纽约州提供1660万美元扶持储能</h4>
			          <p class="tip"></p>
			          <div class="footer">
			            <!-- <img src="http://jiuyixueyuan.oss-cn-shanghai.aliyuncs.com/jiuyi/system/40ec26ab2a0c4838bef69db87238f3fc.jpg" alt="会务系统有什么好处" />
			        领课网络科技 &nbsp;&nbsp;|&nbsp;&nbsp;-->
			            2022-08-30 17:43:54
			            <!-- <span class="fr">208人已阅</span> -->
			          </div>
			        </div>
			      </div> 
			    <!-- </a> -->
				
				<div class="newItem" onclick="window.open('https://mp.weixin.qq.com/s/RvtaArKpqBBDrQ1mLVyTjg')">
				  <img
				    class="newsImg"
				    src="@/assets/images/news/hangye-3.jpg"
				    alt=""
				  />
				  <div class="text">
				    <h4 class="title">沙戈荒大基地储能103.5MW/207MWh！国内最大光储治沙电站一期并网</h4>
				    <p class="tip"></p>
				    <div class="footer">
				      2023-02-01 17:43:54
				    </div>
				  </div>
				</div>
				
				<!-- <div  class="newItem" onclick="window.open('http://escn.com.cn/news/show-1467699.html')">
				  <img
				    class="newsImg"
				    src="@/assets/images/news/hangye-2.png"
				    alt="纽约州提供1660万美元扶持储能"
				  />
				  <div class="text">
				    <h4 class="title">纽约州提供1660万美元扶持储能</h4>
				    <p class="tip"></p>
				    <div class="footer">
				      2022-08-30 17:43:54
				    </div>
				  </div>
				</div> -->
				
			  </div>
			  
              <div class="none">点击加载更多</div>
            </div>
          </div>
        </div>
        <div class="side-box">
          <div class="side">
            <div class="title">最新动态</div>
            <div class="newList">
              <!-- <a href="/detail.html?1421044908469596162" target="_blank"> -->
                <div class="newItem">
                  <!-- <img src="http://jiuyixueyuan.oss-cn-shanghai.aliyuncs.com/jiuyi/system/b24afb1554c34edc8f77ff9beac1b826.jpg" alt="网校系统有什么作用？" /> -->
                  <div class="text" onclick="window.open('http://escn.com.cn/news/show-1467699.html')">
                    <div class="title">纽约州提供1660万美元扶持储能</div>
                    <div class="time">2022-09-19 10:47:52</div>
                  </div>
				  <div class="text" onclick="window.open('http://escn.com.cn/news/show-1467754.html')">
				    <div class="title">倍数增长的储能</div>
				    <div class="time">2022-09-19 01:20:12</div>
				  </div>
				  <div class="text" onclick="window.open('http://escn.com.cn/news/show-1466592.html')">
				    <div class="title">新型储能电站要来了吗？</div>
				    <div class="time">2022-09-17 10:47:52</div>
				  </div>
				  <div class="text" onclick="window.open('http://escn.com.cn/news/show-1468096.html')">
				    <div class="title">该给疯涨的锂价降温了</div>
				    <div class="time">2022-09-16 09:22:12</div>
				  </div>
                </div>
              <!-- </a> -->


            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
    // import {swiper,swiperSlide} from "vue-awesome-swiper";
    // import "swiper/dist/css/swiper.css";
	import commonTitle from '@/components/common/commonTitle.vue'
  export default {
    components: {
    //   swiper,
    //   swiperSlide,
	commonTitle,
    },
    data() {
        return {
			data:["news"],
            datas: [
                "https://ns-strategy.cdn.bcebos.com/ns-strategy/upload/fc_big_pic/part-00693-2745.jpg",
                require("../assets/images/banner/2.jpg"),
				require("../assets/images/banner/epc-1.jpg"),
				require("../assets/images/banner/epc-2.jpg"),
            ],
            activeIndex:0,
            tabList:[
                {name:'公司新闻',id:0},
                // {name:'产品介绍',id:1},
                {name:'行业动态',id:2},
            ]
        }
    },
	mounted() {
		window.scrollTo(0, 0)
	},
    methods: {
	  jumpto(path) {
		this.$router.replace(path)  
	  },
      onSwiper(swiper) {
        console.log(swiper);
      },
      selectTab(e){
          this.activeIndex = e
      },
      onSlideChange() {
        console.log('slide change');
      },
    },
  };
</script>
<style lang="scss">
@import "@/assets/css/news.scss";
</style>